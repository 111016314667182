import {assign, find, flatMap, forEach, get, groupBy, map, set, size, uniq, values} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import RecommendationAnnotation from 'components/RecommendationAnnotation';
import KyError from 'components/errors/KyError';
import InfoLink from 'components/links/Info';
import Link from 'components/links/Link';
import FactSection from 'components/shared/fact_section';
import useAppContext from 'conf/AppContext';

import 'components/GenotypePicker/index.scss';

const propTypes = {
  annotationId: PropTypes.string.isRequired,
  note: PropTypes.element,
  source: PropTypes.string,
  alleles: PropTypes.object,
  phenotypes: PropTypes.object,
  chemicalId: PropTypes.string,
  gsiResults: PropTypes.object,
  gsiQuery: PropTypes.object,
};
/**
 * Displays fields for the user to pick a specific genotype which is then used to display specific recommendations.
 *
 * @param {object} props - props container
 * @param {string} props.annotationId - accession ID of the "parent" annotation
 * @param {*} props.note - optional note to display below picker
 * @param {string} props.source - optional data source string for source-specific content
 * @param {object} props.alleles - a map of genes to alleles to use as options
 * @param {object} props.phenotypes - a map of genes to phenotypes to use as options
 * @param {string} props.chemicalId - optional, the specific chemical to show the recommendation for
 * @param {object} props.gsiResults - optional, results from a GSI query
 * @param {object} props.gsiQuery - optional, the chosen values from a GSI query in a map of gene symbol to 3-value array
 */
export default function GenotypePicker({annotationId, note, source, alleles, phenotypes,
  chemicalId, gsiResults, gsiQuery}) {
  const appContext = useAppContext();
  const [drugs, setDrugs] = useState(undefined);
  const [picks, setPicks] = useState({});
  const genes = Object.keys(alleles);

  useEffect(() => {
    const initPicks = {};
    forEach(
      Object.keys(alleles),
      (gene) => {
        initPicks[gene] = [null, null, null];
      });
    setPicks(assign(initPicks, gsiQuery));
  }, [alleles, gsiQuery]);

  const initialRecs = !gsiResults ? [] : map(values(gsiResults), (r) => r[source.toLowerCase()]);

  const genePhenotypeMap = groupBy(phenotypes, (p) => p.gene.symbol);

  const onChange = (idx, symbol) => async (e) => {
    const pickedAllele = e.target.value;
    const newPicks = set(picks || {}, `${symbol}.${idx}`, pickedAllele);
    setPicks(newPicks);

    try {
      const r = await appContext.api.post(
        `site/gsi/${annotationId}/${chemicalId}`,
        {json: newPicks, parseJson: true},
      );
      setDrugs(find(values(r?.data?.drugs), (d) => d.drug.id === chemicalId) || null);
    } catch (err) {
      appContext.toastError(<KyError kyError={err} />);
    }
  };

  const renderGenePicker = (symbol) => (
    <div className="genotype-specific-annotations-picker" key={symbol}>
      <div>Pick genotype or phenotype for {symbol}</div>
      <div>
        <span className="mr-2">
          <select onChange={onChange(0, symbol)} disabled={picks[symbol] && !!picks[symbol][2]}>
            <option value="">--</option>
            {map(alleles[symbol], (alleleName) => <option selected={get(picks, symbol + '.0') === alleleName} value={alleleName} key={alleleName}>{alleleName}</option>)}
          </select>
          {!singlePloidy(symbol) && (
            <select onChange={onChange(1, symbol)} disabled={picks[symbol] && !!picks[symbol][2]}>
              <option value="">--</option>
              {map(alleles[symbol], (alleleName) => <option selected={get(picks, symbol + '.1') === alleleName} value={alleleName} key={alleleName}>{alleleName}</option>)}
            </select>
          )}
        </span>
        or
        <span className="ml-2">
          <select onChange={onChange(2, symbol)} disabled={picks[symbol] && (!!picks[symbol][0] || !!picks[symbol][1])} defaultValue={get(picks, symbol + '.2')}>
            <option value="">--</option>
            {map(genePhenotypeMap[symbol], (gp) => <PhenotypeOption key={gp.id} selected={get(picks, symbol + '.2') === (gp.score || gp.name)} {...gp} />)}
          </select>
        </span>
      </div>
    </div>
  );

  let resultSection;
  if (drugs || initialRecs) {
    const resultGuideline = find(
      flatMap(values(drugs || initialRecs), (d) => d.annotations),
      (g) => g?.id === annotationId,
    );
    if (resultGuideline) {
      const {genotypes, hasNoRecommendation, phenotypes: resultPhenotypes, recommendationAnnotations} = resultGuideline;
      if (size(recommendationAnnotations) > 0) {
        const multipopulation = size(uniq(map(recommendationAnnotations, (a) => a.population))) > 1;
        resultSection = (
          <div className="guidelinePickerResultSection">
            {map(recommendationAnnotations, (r) =>
              <RecommendationAnnotation key={r.id} phenotypes={resultPhenotypes} genotypes={genotypes} multipopulation={multipopulation} {...r} />)}
            <Link href={`/genotypeResults?q=${encodeURIComponent(JSON.stringify(picks || gsiQuery))}`}>More annotations at the GSI</Link>
          </div>
        );
      } else if (hasNoRecommendation) {
        resultSection = (
          <div className="guidelinePickerResultSection">
            <div className="recommendationAnnotation">This genotype has no recommendation</div>
          </div>
        );
      }
    }
  }

  return (
    <FactSection title="Specify a genotype for specific annotations" id="tour-annotation-picker">
      {map(genes, renderGenePicker)}
      <small>
        <p>Alleles not present in the above pull-down menus have no guideline recommendation.</p>
        {source === 'DPWG' && (
          <p>
            <InfoLink href="/page/dpwgMapping">Important information about alleles in DPWG guidelines.</InfoLink>
          </p>
        )}
      </small>
      {note}
      {resultSection}
    </FactSection>
  );
}
GenotypePicker.propTypes = propTypes;

function singlePloidy(gene) {
  return gene === 'MT-RNR1';
}

function PhenotypeOption({name, score, selected = false}) {
  let displayName = name;
  let value = name;
  if (score) {
    displayName = `${score} (${name})`;
    value = score;
  }
  return (
    <option
      selected={selected}
      value={value}
    >
      {displayName}
    </option>
  );
}
