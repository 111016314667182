import PropTypes from 'prop-types';

import ApproveAndDiffButtons from 'components/Button/ApproveAndDiff';
import LiteratureCitation from 'components/Literature/Citation';
import EditControls from 'components/edit/EditControls';
import Link from 'components/links/Link';
import FactSection from 'components/shared/fact_section';
import HTMLContainer from 'components/shared/html_container';
import {resourceProps} from 'conf/types';


const propTypes = {
  id: PropTypes.string,
  gene: resourceProps,
  approved: PropTypes.bool,
  published: PropTypes.bool,
};

export default function VipOverviewTab({id, gene, approved, published}) {
  return (
    <div id="tour-vip-main-text">
      <EditControls>
        <ApproveAndDiffButtons objCls="Gene" objId={gene.id} approved={approved} published={published} iconOnly={false} />
      </EditControls>

      {gene?.vipSummary?.html && (
        <HTMLContainer html={gene.vipSummary.html} reducedHeaderSize={false} className="mb-3" />
      )}

      {gene?.vipCitation && (
        <>
          <h3>Citation</h3>
          <LiteratureCitation {...gene.vipCitation} />
        </>
      )}

      <FactSection title="History" sectionClasses="mt-4">
        We have changed how we display VIP content. Read more about the history of VIPs on our <Link href="/page/vipHistory">VIP history page</Link>.
      </FactSection>
    </div>
  );
}
VipOverviewTab.propTypes = propTypes;
