import {map, size} from 'lodash';

import ResourceLink from 'components/links/Resource';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import {DataSource} from 'conf/enums';
import RelatedGenesFact from 'pages/Allele/OverviewTab/RelatedGenesFact';

export default function AlleleOverviewTab({allele, haplotypes = [], variant}) {
  const {definitions, geneFunctions = [], relatedGenes = []} = allele;

  return (
    <div className="overviewTab">
      <FactSection title="Associations">
        <RelatedGenesFact genes={relatedGenes} />
        <Fact label="Associated Reference SNP">
          {variant && <ResourceLink resource={variant} />}
        </Fact>
        <Fact label="Associated Haplotypes">
          {size(haplotypes) > 0 && (
            <ul>
              {map(haplotypes, (h) => <li key={h.accessionId}><ResourceLink resource={h} /></li>)}
            </ul>
          )}
        </Fact>
      </FactSection>

      <FactSection title="Definitions">
        {definitions.map((def, i) => (
          <Fact key={def.id} label={def.assembly || 'Gene'} contentClasses="fixed-width-font">
            {def.name}
          </Fact>
        ))}
      </FactSection>

      {size(geneFunctions) > 0 && (
        <FactSection title="Gene Function" notAvailableWhenEmpty={false}>
          {map(geneFunctions, (f) => (
            <Fact key={f.id} label={`${DataSource.displayName[f.source]} Function`}>
              {f.name}
            </Fact>
          ))}
        </FactSection>
      )}
    </div>
  );
}
