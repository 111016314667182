import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {filter, map, orderBy, size} from 'lodash';
import PropTypes from 'prop-types';
import {useState} from 'react';

import Button from 'components/Button';
import FdaPgxAssociationTable from 'components/FdaPgxAssociation/table';
import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import Section from 'components/Section';
import ViewLinkCell, {viewLinkExportHeader, viewLinkExportValue} from 'components/Table/Cell/ViewLink';
import TextFilter from 'components/Table/Filter/Text';
import SimpleTable from 'components/Table/Simple';
import {
    defaultRelatedGenesColumn,
    defaultSourceColumn,
    fixedColumnStyle,
    flexColumnStyle,
} from 'components/Table/columnHelpers';
import {renderRetiredTag} from 'components/Tag/Retired';
import KyError from 'components/errors/KyError';
import FeedbackLink from 'components/links/Feedback';
import Link from 'components/links/Link';
import Fact from 'components/shared/fact';
import HtmlContainer from 'components/shared/html_container';
import useAppContext from 'conf/AppContext';
import Types, {getBaseUrl} from 'conf/types';
import {useGet} from 'helpers/KyHooks';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  objCls: PropTypes.string.isRequired,
};

export default function PrescribingInfoTab({id, name, objCls}) {
  const appContext = useAppContext();
  const [showRetired, setShowRetired] = useState(false);

  const target = objCls === 'combination' ? id : `${objCls}/${id}`;
  const {response, error} = useGet(`site/tab/prescribingInfo/${target}`, {
    searchParams: {
      // need MOST for LiteratureAware
      view: 'most',
    },
    cacheResponse: !appContext.isPreview,
  });


  let body = <Loading />;
  let totalAnnotations;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    const {dosingGuidelines, prescribingLabels, fdaPgxAssociations} = response.data;

    const renderTotalAnnotations = () => {
      if (dosingGuidelines?.length === 0 && prescribingLabels?.length === 0 && fdaPgxAssociations?.length === 0) {
        return null;
      }
      return (
        <section>
          <ul className="list-unstyled">
            {dosingGuidelines.length > 0 && <li key="dosingGuidelines"><a href="#guideline-annotations">Clinical Guideline Annotations</a>: {dosingGuidelines.length}</li>}
            {prescribingLabels.length > 0 && <li key="prescribingLabels"><a href="#drug-label-annotations">Drug Label Annotations</a>: {prescribingLabels.length}</li>}
            {fdaPgxAssociations.length > 0 && <li key="fdaPgxAssociations"><a href="#fda-pgx-annotations">FDA PGx Associations</a>: {fdaPgxAssociations.length}</li>}
          </ul>
        </section>
      );
    };
    totalAnnotations = renderTotalAnnotations();

    if (dosingGuidelines.length === 0 && prescribingLabels.length === 0 && fdaPgxAssociations.length === 0) {
      body = (
        <p>
          PharmGKB contains no prescribing info for this. <FeedbackLink>Contact us</FeedbackLink> to
          report known genotype-based clinical guidelines.
        </p>
      );
    } else {
      const retiredCount = size(filter(dosingGuidelines, (g) => !!g.retirementReason));
      const customFilters = [];
      if (retiredCount > 0) {
        customFilters.push((
          <Button key="retired" className="btn-outline-info" actionHandler={() => setShowRetired((p) => !p)}>
            <FontAwesomeIcon icon={['fal', showRetired ? 'check-square' : 'square']} /> Show {retiredCount} Retired
          </Button>
        ));
      }

      const data = orderBy(
        map(
          filter(dosingGuidelines, (g) => showRetired || !g.retirementReason),
          (g) => ({parentId: id, parentName: name, parentCls: objCls, ...g}),
        ),
        ['source', 'recommendation', 'name'],
        ['asc', 'desc', 'asc'],
      );

      body = (
        <>
          {dosingGuidelines?.length > 0 && (
            <Section title="Clinical Guideline Annotations" id="guideline-annotations">
              <InlinePage id="dosingGuideHeader" className="inlinePage--blurb" />
              <SimpleTable
                data={data}
                columns={[
                  {
                    id: 'view',
                    Header: '',
                    className: 'viewLinkCell',
                    // width is required for sticky column to work properly
                    ...fixedColumnStyle(90),
                    accessor: (originalRow) => originalRow,
                    Cell: (objCls === 'chemical' ? ChemicalLinkCell : ViewLinkCell),
                    getColumnExportValue: viewLinkExportHeader,
                    getCellExportValue: viewLinkExportValue,
                  },
                  defaultSourceColumn,
                  defaultRelatedGenesColumn,
                  {
                    id: 'title',
                    Header: 'Title',
                    accessor: 'name',
                    Cell: (objCls === 'chemical' ? ChemicalTitleCell : TitleCell),
                    sortable: true,
                    Filter: TextFilter,
                    ...flexColumnStyle({width: 400, grow: 4}),
                  },
                ]}
                customFilters={customFilters}
              />
            </Section>
          )}
          {prescribingLabels?.length > 0 && (
            <Section title="Drug Label Annotations with Genotype-Based Prescribing Information" id="drug-label-annotations">
              <InlinePage id="drugLabelsWithRxInfo" className="inlinePage--blurb" />
              {map(prescribingLabels, (l) => (
                <Fact label={l.name} key={l.id}>
                  <HtmlContainer className="mb-0" html={l.prescribingMarkdown.html} />
                  <p><Link href={Types.labelAnnotation.url(l.id)}>Continue reading {l.name}</Link></p>
                </Fact>
              ))}
            </Section>
          )}
          <div id="fda-pgx-annotations">
            <FdaPgxAssociationTable fdaPgxAssociations={fdaPgxAssociations} />
          </div>
        </>
      );
    }
  }

  return (
    <Section className="prescribingInfoTab" title="Genotype/Phenotype-based Prescribing Info">
      {totalAnnotations}
      <InlinePage id="prescribingInfoTab" className="inlinePage--blurb" />
      {body}
    </Section>
  );
}
PrescribingInfoTab.propTypes = propTypes;


function TitleCell({value, row}) {
  return (
    <>
      <div><Link href={Types.guidelineAnnotation.url(row.original.id)}>{value}</Link></div>
      {!row.original.recommendation && (<b className="text-danger"><i>No recommendation</i></b>)}
      {renderRetiredTag({obj: row.original})}
    </>
  );
}

function ChemicalLinkCell({value}) {
  return (
    <Link
      href={`/${value.parentCls}/${value.parentId}${getBaseUrl(value.id, value.objCls)}`}
      className="btn btn-xs btn-primary"
      iconOnly={true}
      title="Details"
    >
      Details
    </Link>
  );
}

function ChemicalTitleCell({value, row}) {
  return (
    <>
      <Link href={`/${row.original.parentCls}/${row.original.parentId}${getBaseUrl(row.original.id, row.original.objCls)}`}>
        {value}
      </Link>
      {!row.original.recommendation && (<div className="font-weight-bold text-danger"><i>No recommendation</i></div>)}
      {renderRetiredTag({obj: row.original})}
    </>
  );
}
