import {useEffect} from 'react';

import chemicalBackgroundImage from 'images/homepage/background-chemical.svg';
import guidelineBackgroundImage from 'images/homepage/background-dosing-guideline.svg';
import labelBackgroundImage from 'images/homepage/background-drug-label.svg';
import geneBackgroundImage from 'images/homepage/background-gene.svg';
import literatureBackgroundImage from 'images/homepage/background-literature.svg';
import lineCapImage from 'images/homepage/line-cap.svg';
import lineMiddleImage from 'images/homepage/line-middle.svg';

import Button from 'components/Button';
import Partners from 'components/Home/Partners';
import SearchBar from 'components/Search/Bar';
import SplitText from 'components/SplitText';
import Svg from 'components/shared/Svg';
import {useTour} from 'components/tour/hook';
import logger from 'conf/Logger';
import {useGet} from 'helpers/KyHooks';


export default function ClinpgxHomePage({}) {

  const {response: resourceStats, error: statsError} = useGet('report/stats', {
    cacheResponse: true,
  });
  if (statsError) {
    logger.error(statsError);
  }

  const readyForTour = useTour('home');
  useEffect(() => {
    if (resourceStats) {
      readyForTour();
    }
  }, [readyForTour, resourceStats]);

  return (
    <div className="home-page">
      <div className="search-ctn">
        <SearchBar site="ClinPGx" />
      </div>

      <div className="home-splash">
        <div className="container">
          <div className="row">
            <div className="col-md-8 home-splash_text">
              <h1 className="title">ClinPGx</h1>
              <p className="lead">PharmGKB has evolved into ClinPGx, the new home for PGx knowledge and implementation.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="info-hero info-hero--animated">
        <Svg className="animated-line" src={lineMiddleImage} ariaHidden={true} />

        <div className="background-images">
          <Svg className="chemical" src={chemicalBackgroundImage} ariaHidden={true} />
          <Svg className="dosing-guideline" src={guidelineBackgroundImage} ariaHidden={true} />
          <Svg className="drug-label" src={labelBackgroundImage} ariaHidden={true} />
          <Svg className="gene" src={geneBackgroundImage} ariaHidden={true} />
          <Svg className="literature" src={literatureBackgroundImage} ariaHidden={true} />
        </div>

        <div className="container">
          <div className="row about-ctn">
            <div className="col-md-6">
              <div className="what-is tour-moreinfo">
                <h3>What Is<br />Pharmaco<span className="genomics">genomics</span>?</h3>
                <p className="description">The study of the relationship<br />between genetic variations and how<br /> our body responds to medications.</p>
                <p><Button href="/whatIsPharmacogenomics" aria-label="What is Pharmacogenomics?" className="btn-dark btn-lg">Tell me more</Button></p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="about tour-learnpgkb">
                <h3>
                  Clinical<br />
                  Pharmaco<span className="genomics">genomics</span>.<br />
                  Implementation.
                </h3>
                <p className="description"><b>ClinPGx</b> is a comprehensive resource that<br />curates knowledge about the impact of genetic<br />variation on drug response for clinicians<br />and researchers.</p>
                <p><Button href="/whatIsPharmgkb" aria-label="What is ClinPGx?" className="btn-light btn-lg">Learn more about ClinPGx</Button></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="mx-auto readable-width">
          <div className="embed-responsive embed-responsive-16by9">
            <iframe title="Introduciton to pharmacogenomics video" className="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/lM7j1v5PInc?rel=0" allowFullScreen={true} />
          </div>
        </div>
      </div>

      <div className="container home-section">
        <div className="row">
          <div className="col-md-4 mb-3">
            <h3 className="section-heading resources">
              <SplitText text="Knowledge" />
            </h3>
            <div className="resource-center"><a href="/page/cancerPgx" className="btn btn-outline-info btn-lg text-dark">Cancer Pharmacogenetics</a></div>
            <div className="resource-center"><a href="/page/DrugUseAndAddiction" className="btn btn-outline-info btn-lg text-dark">PGx in Drug Use and Addiction</a></div>
            <div className="resource-center"><a href="/page/pgxGeneRef" className="btn btn-outline-info btn-lg text-dark">Gene Information Tables</a></div>
          </div>
          <div className="col-md-4 mb-3">
            <h3 className="section-heading resources">
              <SplitText text="Implementation" animationFrom={{opacity: 0, transform: 'translate3d(0,-40px,0)'}} />
            </h3>
            <div className="resource-center"><a href="/fdaPgxAssociations" className="btn btn-outline-info btn-lg text-dark">FDA PGx Associations</a></div>
            <div className="resource-center"><a href="/prescribingInfo" className="btn btn-outline-info btn-lg text-dark">Drugs with Prescribing Information</a></div>
            <div className="resource-center"><a href="/ampAllelesToTest" className="btn btn-outline-info btn-lg text-dark">AMP Recommendations</a></div>
          </div>
          <div className="col-md-4 mb-3">
            <h3 className="section-heading resources">
              <SplitText text="Education" animationFrom={{opacity: 0, transform: 'translate3d(0,0,-40px)'}} />
            </h3>
            <div className="resource-center"><a href="/page/educationalResources" className="btn btn-outline-info btn-lg text-dark">Educational Resources</a></div>
            <div className="resource-center"><a href="/page/biogeographicalGroups" className="btn btn-outline-info btn-lg text-dark">Biogeographical Groups for PGx</a></div>
            <div className="resource-center"><a href="/page/curationTips" className="btn btn-outline-info btn-lg text-dark">PGx Publication Tips</a></div>
          </div>
        </div>
      </div>

      <div className="line-cap line-cap-top line-cap--partners"><Svg src={lineCapImage} ariaHidden={true} /></div>
      <div className="line-cap line-cap-btm line-cap--partners"><Svg src={lineCapImage} ariaHidden={true} /></div>
      <Partners clinpgx={true} />

    </div>
  );
}
