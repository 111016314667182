import {map, omit} from 'lodash';

import ResourceLink from 'components/links/Resource';
import CuratorOnly from 'components/shared/curator_only';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import RelatedGenesFact from 'pages/Allele/OverviewTab/RelatedGenesFact';

export default function VariationHaplotypeOverviewTab({
  id,
  relatedGenes,
  members,
  previousHaplotype,
}) {
  return (
    <FactSection title="Properties">
      <Fact label="Accession ID">{id}</Fact>
      <RelatedGenesFact genes={relatedGenes} />
      <Fact label="Defining Alleles">
        <ul>
          {map(members, (m) => <li key={m.id}><ResourceLink resource={omit(m, 'symbol')} /></li>)}
        </ul>
      </Fact>
      <CuratorOnly>
        <Fact label="Previous Haplotype" hideWhenEmpty={true}>
          <ResourceLink resource={previousHaplotype} />
        </Fact>
      </CuratorOnly>
    </FactSection>
  );
}
