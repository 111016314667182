import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {map} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';

import SearchBarAutocomplete from 'components/Search/Bar/Autocomplete';
import useSearchBar, {pinnableTypes} from 'components/Search/Bar/Provider';
import {combinationUrl} from 'components/Search/utils';
import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';
import {getBaseUrl, normalizeType} from 'conf/types';
import {analyticsEvent} from 'helpers/analytics';


const KEYUP_IGNORED_TAGS = ['INPUT', 'SELECT', 'BUTTON', 'TEXTAREA'];
const PATH_REGEX = /^\/([a-z]+)\/(PA[0-9]+)(?:,(PA[0-9]+))*/;


const propTypes = {
  site: PropTypes.string,
  maxAutocompleteItems: PropTypes.number,
};
/**
 * Renders the site-wide search bar.
 */
export default function SearchBar({site = 'PharmGKB', maxAutocompleteItems = 5}) {
  const appContext = useAppContext();
  const searchBar = useSearchBar();
  const [focused, setFocused] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const inputRef = useRef(null);
  const searchBarRef = useRef(null);

  const _setFocused = (val) => {
    if (focused === val) {
      return;
    }
    setFocused(val);
  };

  const componentIsMounted = useRef(true);
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    window.addEventListener('keydown', keyDownListener, true);
    return () => {
      componentIsMounted.current = false;
      // eslint-disable-next-line no-undef
      window.removeEventListener('keydown', keyDownListener, true);
    };
  }, []);

  useEffect(() => {
    const match = appContext.getCurrentPath().match(PATH_REGEX);
    let newPinnedHits = [];
    if (match && pinnableTypes.includes(match[1])) {
      newPinnedHits = searchBar.state.pinnedHits;
    }
    searchBar.initializeSearchBar(newPinnedHits);
    closeAutocomplete();
  }, [appContext.getCurrentPath()]);


  function keyDownListener(event) {
    if (event.isComposing || event.keyCode === 229) {
      return;
    }
    if (event.key === '/' && !KEYUP_IGNORED_TAGS.includes(event.target.tagName)) {
      if (inputRef.current) {
        inputRef.current.focus();
        event.preventDefault();
      }
    }
  }

  /**
   * Makes sure autocomplete result dropdown is closed.
   */
  function closeAutocomplete() {
    _setFocused(false);
    if (inputRef.current && document.activeElement === inputRef.current) {
      inputRef.current.blur();
    }
  }

  const onBlur = (event) => {
    // event.relatedTarget - element receiving focus (if any)
    if (searchBarRef.current && searchBarRef.current !== event.relatedTarget &&
      !searchBarRef.current.contains(event.relatedTarget)) {
      // focus shifted outside of SearchBar
      _setFocused(false);
      searchBar.resetPins();
    }
  };


  const onInputFocus = () => {
    _setFocused(true);
    if (componentIsMounted.current) {
      setSelectedId(-1);
    }
  };


  const removeResource = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const closestAncestor = event.target.closest('button[data-id]');
    if (closestAncestor && closestAncestor.getAttribute('data-id')) {
      const id = closestAncestor.getAttribute('data-id');
      analyticsEvent('search-removePill-click');
      searchBar.removePinnedHit(id);
      if (!searchBar.isSearchPage()) {
        inputRef.current.focus();
      }
    } else {
      logger.error('Cannot find data-id of resource to remove from search bar');
    }
  };

  const updateQuery = (event) => {
    const newQuery = event.target.value;
    searchBar.updateQuery(newQuery);
    if (componentIsMounted.current) {
      setSelectedId(-1);
    }
  };

  const onClickSubmit = () => {
    analyticsEvent('search-btn-click');
    closeAutocomplete();
    searchBar.goToSearchPage();
  };

  /**
   * Checks if caret is at the start of the input field.
   * Fallback for old browsers is to check that there's no query.
   *
   * @return {boolean} true if caret is at the start of the input field; false otherwise
   */
  const _atInputStart = () => {
    if ('selectionStart' in inputRef.current) {
      // noinspection JSUnresolvedVariable
      return inputRef.current.selectionStart === 0 && inputRef.current.selectionEnd === 0;
    }
    return !searchBar.state.query;
  };

  const updateAutocompleteIndex = (newIndex) => {
    if (componentIsMounted.current) {
      setSelectedId(newIndex);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Backspace' && searchBar.hasPinned() && _atInputStart()) {
      event.preventDefault();
      const {id} = searchBar.state.pinnedHits[searchBar.state.pinnedHits.length - 1];
      analyticsEvent('search-removePill-backspace');
      searchBar.removePinnedHit(id);
    } else if (event.key === 'Enter') {
      _setFocused(false);
      if (selectedId === -1) {
        // nothing chosen
        if (searchBar.hasPinned() && !searchBar.state.query) {
          let targetUrl;
          if (searchBar.state.pinnedHits.length > 1) {
            targetUrl = combinationUrl(searchBar.state.pinnedHits);
          } else {
            const hit = searchBar.state.pinnedHits[0];
            targetUrl = getBaseUrl(hit.id, hit.objCls);
          }
          if (appContext.getCurrentPath() !== targetUrl) {
            searchBar.updatePins();
            appContext.redirect(targetUrl);
          } else {
            inputRef.current?.blur();
          }
        } else if (searchBar.state.query || !searchBar.hasPinned()) {
          searchBar.goToSearchPage();
        }
      } else {
        analyticsEvent('search-autocomplete', {keypress: 'by_selection'});
        const hit = searchBar?.autocomplete?.hits?.[selectedId];
        // url is based on current searchBar state
        const url = searchBar.hasPinned() ? `${combinationUrl(searchBar.state.pinnedHits)},${hit.id}` : getBaseUrl(hit.id, hit.objCls);
        // now update searchBar state and redirect
        const hits = searchBar.state.pinnedHits;
        hits.push({id: hit.id, name: hit.name, objCls: normalizeType(hit.objCls)});
        searchBar.updatePins(hits);
        if (appContext.getCurrentPath() !== url) {
          appContext.redirect(url);
        } else {
          inputRef.current?.blur();
        }
      }
    } else if (event.key === 'Escape') {
      closeAutocomplete();
    } else if (event.key === 'ArrowDown') {
      const maxIndex = Math.min(searchBar?.autocomplete?.hits?.length || 0, maxAutocompleteItems) - 1;
      if (selectedId < maxIndex) {
        updateAutocompleteIndex(selectedId + 1);
      }
    } else if (event.key === 'ArrowUp') {
      const maxIndex = Math.min(searchBar?.autocomplete?.hits?.length || 0, maxAutocompleteItems) - 1;
      if (selectedId === -1) {
        updateAutocompleteIndex(maxIndex);
      } else if (selectedId === 0) {
        updateAutocompleteIndex(-1);
      } else if (selectedId > 0) {
        updateAutocompleteIndex(selectedId - 1);
      }
    }
  };


  const placeholder = searchBar.state.pinnedHits?.length > 0
    ? 'Add a term to make a combination...'
    : `Search ${site}`;

  const renderResource = (resource) => {
    const {id, name, objCls} = resource;
    const type = normalizeType(objCls);
    return (
      <span className={`searchBar__resource ${type}-bg`} key={id}>
        <span className="searchBar__resource__name">{name}</span>
        <button
          type="button"
          className="searchBar__resource__remove"
          data-id={id}
          onClick={removeResource}
          aria-label={`Remove ${name} from search`}
        >
          <FontAwesomeIcon icon={['fal', 'times']} />
        </button>
      </span>
    );
  };

  const renderAutocomplete = () => {
    if (focused && searchBar.showAutocomplete()) {
      return (
        <SearchBarAutocomplete
          hits={searchBar?.autocomplete?.hits}
          total={searchBar?.autocomplete?.total}
          selectedResult={selectedId}
        />
      );
    }
    return '';
  };

  return (
    <>
      <div className={clsx('searchBar', {'searchBar--focused': focused})} onBlur={onBlur} ref={searchBarRef}>
        <div className="searchBar__input dropdown">
          <div className="searchBar__input__query">
            {map(searchBar.state.pinnedHits, (h) => renderResource(h))}
            <input
              ref={inputRef}
              key="input"
              type="text"
              aria-label={`Search ${site}`}
              placeholder={placeholder}
              value={searchBar.state.query}
              onFocus={onInputFocus}
              onChange={updateQuery}
              onKeyDown={onKeyDown}
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="off"
              spellCheck={false}
              maxLength="200"
            />
          </div>
          {renderAutocomplete()}
        </div>
        <button onClick={onClickSubmit} type="submit">
          <FontAwesomeIcon icon="search" /><span className="sr-only">Search</span>
        </button>
      </div>
      {focused && <div className="searchBar__dimmer" />}
    </>
  );
}
SearchBar.propTypes = propTypes;
