import {compact, every, omit, pickBy, size, sortBy, values as loValues} from 'lodash';
import {useState} from 'react';

import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import ErrorMessage from 'components/errors/ErrorMessage';
import KyError from 'components/errors/KyError';
import FullFluidWidthTemplate from 'components/templates/FullFluidWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';
import {analyticsEvent} from 'helpers/analytics';
import GenotypeGenePicker from 'pages/genotype/GenePicker';

import './index.scss';


/**
 * The GSI page for selecting genotypes
 *
 * @param {object} props - props
 * @param {object} props.genoQueryParam - genotypes that are coming from a query parameter
 * @param {Array<string>} props.drugs - array of drug IDs to use for defaultValues
 */
export default function GenotypePage({genoQueryParam, drugs: initialDrugIds}) {
  const appContext = useAppContext();
  const [submitError, setSubmitError] = useState(undefined);
  const {response, error} = useGet('site/gsi/options', {
    cacheResponse: true,
  });

  const genes = sortBy(response?.data?.genes, (g) => g.genesymbol);
  const drugOptions = sortBy(response?.data?.drugs, (g) => g.name);

  const handleSubmit = (data) => {
    analyticsEvent('gsi-genotypePick', {choice: JSON.stringify(data)});
    const formEmpty =
      size(data) === 0 ||
      every(loValues(data), (alleleArray) => every(alleleArray, (allele) => !allele));
    if (formEmpty) {
      setSubmitError(<div className="mt-3"><ErrorMessage title="Empty Genotype" message="Pick at least one genotype before making a report" /></div>);
    } else {
      const url = `/genotypeResults?q=${encodeURIComponent(JSON.stringify(data))}`;
      appContext.redirect(url);
    }
  };

  /**
   * This will add the form data to the URL and not cause a page nav so if someone hits their back button it will NOT
   * clear the form. We need to separate the genotype data from the drug data in order to use it in the URL.
   *
   * @param {object} data - form data from the genotype picker form
   */
  const handleUpdate = (data) => {
    const qFragment = JSON.stringify(pickBy(omit(data, 'drugs'), (v) => size(compact(v)) > 0));
    let drugsQp = '';
    if (size(data.drugs) > 0) {
      drugsQp = `&drugs=${encodeURIComponent(JSON.stringify(data.drugs))}`;
    }
    appContext.redirect(`/genotype?q=${encodeURIComponent(qFragment)}${drugsQp}`, false);
  };

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response?.data) {
    body = (
      <>
        <div className="container">
          <InlinePage id="gsiHeader" className="mb-3" />
          <InlinePage id="gsiPickerHeader" className="mb-3" />
        </div>
        <div className="container">
          <GenotypeGenePicker
            genes={genes}
            drugs={drugOptions}
            defaultValues={omit(genoQueryParam, 'drugs')}
            defaultDrugs={initialDrugIds}
            onSubmit={handleSubmit}
            onUpdate={handleUpdate}
          />
          {submitError}
        </div>
      </>
    );
  }

  return (
    <FullFluidWidthTemplate title="GSI: Genotype Selection Interface">
      {body}
    </FullFluidWidthTemplate>
  );
}
