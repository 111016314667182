import {library} from '@fortawesome/fontawesome-svg-core';
// brand icons
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faGithub} from '@fortawesome/free-brands-svg-icons/faGithub';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import {faMarkdown} from '@fortawesome/free-brands-svg-icons/faMarkdown';
import {faWordpress} from '@fortawesome/free-brands-svg-icons/faWordpress';
import {faYoutube} from '@fortawesome/free-brands-svg-icons/faYoutube';
// duotone icons
import {faArrowUpRightFromSquare} from '@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare';
import {faDownload as fadDownload} from '@fortawesome/pro-duotone-svg-icons/faDownload';
import {faSort} from '@fortawesome/pro-duotone-svg-icons/faSort';
import {faSortDown} from '@fortawesome/pro-duotone-svg-icons/faSortDown';
import {faSortUp} from '@fortawesome/pro-duotone-svg-icons/faSortUp';
import {faSpinnerThird} from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
// light icons
import {faBan} from '@fortawesome/pro-light-svg-icons/faBan';
import {faBinoculars as falBinoculars} from '@fortawesome/pro-light-svg-icons/faBinoculars';
import {faBooks} from '@fortawesome/pro-light-svg-icons/faBooks';
import {faChalkboardTeacher} from '@fortawesome/pro-light-svg-icons/faChalkboardTeacher';
import {faCheckSquare as falCheckSquare} from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import {faCircleInfo as falCircleInfo} from '@fortawesome/pro-light-svg-icons/faCircleInfo';
import {faFileArchive} from '@fortawesome/pro-light-svg-icons/faFileArchive';
import {faFileCode} from '@fortawesome/pro-light-svg-icons/faFileCode';
import {faFileExcel} from '@fortawesome/pro-light-svg-icons/faFileExcel';
import {faFileImage} from '@fortawesome/pro-light-svg-icons/faFileImage';
import {faFilePdf} from '@fortawesome/pro-light-svg-icons/faFilePdf';
import {faHandPaper} from '@fortawesome/pro-light-svg-icons/faHandPaper';
import {faRobot} from '@fortawesome/pro-light-svg-icons/faRobot';
import {faScrollOld} from '@fortawesome/pro-light-svg-icons/faScrollOld';
import {faSquare as falSquare} from '@fortawesome/pro-light-svg-icons/faSquare';
import {faTimes as falTimes} from '@fortawesome/pro-light-svg-icons/faTimes';
// regular icons
import {faArrowRotateLeft as farArrowRotateLeft} from '@fortawesome/pro-regular-svg-icons/faArrowRotateLeft';
import {faBinoculars as farBinoculars} from '@fortawesome/pro-regular-svg-icons/faBinoculars';
import {faCheckSquare as farCheckSquare} from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import {faChevronRight as farChevronRight} from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import {faCircleInfo as farCircleInfo} from '@fortawesome/pro-regular-svg-icons/faCircleInfo';
import {faCode as farCode} from '@fortawesome/pro-regular-svg-icons/faCode';
import {faComment as farComment} from '@fortawesome/pro-regular-svg-icons/faComment';
import {faCube} from '@fortawesome/pro-regular-svg-icons/faCube';
import {faEllipsisV} from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import {faExpandAlt} from '@fortawesome/pro-regular-svg-icons/faExpandAlt';
import {faEye as farEye} from '@fortawesome/pro-regular-svg-icons/faEye';
import {faEyeSlash as farEyeSlash} from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import {faFile} from '@fortawesome/pro-regular-svg-icons/faFile';
import {faFileAlt} from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import {faFilter} from '@fortawesome/pro-regular-svg-icons/faFilter';
import {faLink} from '@fortawesome/pro-regular-svg-icons/faLink';
import {faMinusSquare as farMinusSquare} from '@fortawesome/pro-regular-svg-icons/faMinusSquare';
import {faPenToSquare as farPenToSquare} from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import {faPlus as farPlus} from '@fortawesome/pro-regular-svg-icons/faPlus';
import {faPlusSquare as farPlusSquare} from '@fortawesome/pro-regular-svg-icons/faPlusSquare';
import {faPoll} from '@fortawesome/pro-regular-svg-icons/faPoll';
import {faQuestionCircle as farQuestionCircle} from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';
import {faSave as farSave} from '@fortawesome/pro-regular-svg-icons/faSave';
import {faSquare as farSquare} from '@fortawesome/pro-regular-svg-icons/faSquare';
import {faTag as farTag} from '@fortawesome/pro-regular-svg-icons/faTag';
import {faThumbsUp as farThumbsUp} from '@fortawesome/pro-regular-svg-icons/faThumbsUp';
import {faTrashCan} from '@fortawesome/pro-regular-svg-icons/faTrashCan';
import {faTriangleExclamation} from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import {faUser as farUser} from '@fortawesome/pro-regular-svg-icons/faUser';
// solid icons
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import {faArrowToTop} from '@fortawesome/pro-solid-svg-icons/faArrowToTop';
import {faArrowsH} from '@fortawesome/pro-solid-svg-icons/faArrowsH';
import {faBaby} from '@fortawesome/pro-solid-svg-icons/faBaby';
import {faBars} from '@fortawesome/pro-solid-svg-icons/faBars';
import {faBug} from '@fortawesome/pro-solid-svg-icons/faBug';
import {faCalculator} from '@fortawesome/pro-solid-svg-icons/faCalculator';
import {faCandyCane} from '@fortawesome/pro-solid-svg-icons/faCandyCane';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import {faCaretUp} from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import {faCertificate} from '@fortawesome/pro-solid-svg-icons/faCertificate';
import {faCheck} from '@fortawesome/pro-solid-svg-icons/faCheck';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faChevronLeft as fasChevronLeft} from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import {faChevronRight as fasChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faChildReaching} from '@fortawesome/pro-solid-svg-icons/faChildReaching';
import {faCircle} from '@fortawesome/pro-solid-svg-icons/faCircle';
import {faCircleInfo as fasCircleInfo} from '@fortawesome/pro-solid-svg-icons/faCircleInfo';
import {faCloudUploadAlt} from '@fortawesome/pro-solid-svg-icons/faCloudUploadAlt';
import {faCode as fasCode} from '@fortawesome/pro-solid-svg-icons/faCode';
import {faComment as fasComment} from '@fortawesome/pro-solid-svg-icons/faComment';
import {faCopy} from '@fortawesome/pro-solid-svg-icons/faCopy';
import {faEnvelope} from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import {faEye as fasEye} from '@fortawesome/pro-solid-svg-icons/faEye';
import {faEyeSlash as fasEyeSlash} from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import {faFemale} from '@fortawesome/pro-solid-svg-icons/faFemale';
import {faGhost} from '@fortawesome/pro-solid-svg-icons/faGhost';
import {faImage} from '@fortawesome/pro-solid-svg-icons/faImage';
import {faLightsHoliday} from '@fortawesome/pro-solid-svg-icons/faLightsHoliday';
import {faList} from '@fortawesome/pro-solid-svg-icons/faList';
import {faMinus} from '@fortawesome/pro-solid-svg-icons/faMinus';
import {faPenToSquare as fasPenToSquare} from '@fortawesome/pro-solid-svg-icons/faPenToSquare';
import {faPlus as fasPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';
import {faPrint} from '@fortawesome/pro-solid-svg-icons/faPrint';
import {faQuestion} from '@fortawesome/pro-solid-svg-icons/faQuestion';
import {faQuestionCircle as fasQuestionCircle} from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import {faRss} from '@fortawesome/pro-solid-svg-icons/faRss';
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch';
import {faShareSquare} from '@fortawesome/pro-solid-svg-icons/faShareSquare';
import {faSlash} from '@fortawesome/pro-solid-svg-icons/faSlash';
import {faSquare as fasSquare} from '@fortawesome/pro-solid-svg-icons/faSquare';
import {faSync} from '@fortawesome/pro-solid-svg-icons/faSync';
import {faThumbsUp as fasThumbsUp} from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import {faTimes} from '@fortawesome/pro-solid-svg-icons/faTimes';
import {faUndo} from '@fortawesome/pro-solid-svg-icons/faUndo';
import {faUnlink} from '@fortawesome/pro-solid-svg-icons/faUnlink';
import {faUpload} from '@fortawesome/pro-solid-svg-icons/faUpload';
import {faUser as fasUser} from '@fortawesome/pro-solid-svg-icons/faUser';

library.add(
  faArrowLeft, faArrowRight, farArrowRotateLeft, faArrowsH, faArrowToTop, faArrowUpRightFromSquare, faBaby, faBan,
  faBars, falBinoculars, farBinoculars, faBooks, faBug, faCalculator, faCandyCane, faCaretDown, faCaretUp,
  faCertificate, faChalkboardTeacher, faCheck, faCheckCircle, falCheckSquare, farCheckSquare, faChevronDown,
  fasChevronLeft, farChevronRight, fasChevronRight, faChevronUp, faChildReaching, faCircle, faCloudUploadAlt, farCode,
  fasCode, farComment, fasComment, faCopy, faCube, fadDownload, faEllipsisV, faEnvelope, faTriangleExclamation,
  faExpandAlt, farEye, fasEye, farEyeSlash, fasEyeSlash, faFacebookF, faFemale, faFile, faFileAlt, faFileArchive,
  faFileCode, faFileImage, faFileExcel, faFileImage, faFilePdf, faFilter, faGhost, faGithub, faHandPaper, faImage,
  fasCircleInfo, falCircleInfo, farCircleInfo, faLightsHoliday, faLink, faLinkedinIn, faList, faMarkdown, faMinus,
  farMinusSquare, farPenToSquare, fasPenToSquare, farPlus, fasPlus, farPlusSquare, faPoll, faPrint, faQuestion,
  farQuestionCircle, fasQuestionCircle, faRss, faRobot, farSave, faScrollOld, faSearch, faShareSquare, faSlash, faSort,
  faSortDown, faSortUp, faSpinnerThird, falSquare, farSquare, fasSquare, faSync, farTag, faTimes, falTimes, farThumbsUp,
  fasThumbsUp, faTrashCan, faUpload, faUnlink, faUndo, farUser, fasUser, faWordpress, faYoutube,
);
