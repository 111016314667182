import {map} from 'lodash';

import cfthornImage from 'images/staff/cfthorn.jpg';
import cjkleinImage from 'images/staff/cjklein.jpg';
import ingridImage from 'images/staff/ingrid.jpg';
import ksangkuhlImage from 'images/staff/ksangkuhl.jpg';
import lgongImage from 'images/staff/lgong.jpg';
import matthewImage from 'images/staff/matthew.jpg';
import mcarrilloImage from 'images/staff/mcarrillo.jpg';
import mwoonImage from 'images/staff/mwoon.jpg';
import pgkbMapImage from 'images/staff/pgkb-map.png';
import rwhaleyImage from 'images/staff/rwhaley.jpg';
import tkleinImage from 'images/staff/tklein.jpg';
import tmurrayImage from 'images/staff/tmurray.jpg';

import DownloadIcon from 'components/icons/Download';
import FeedbackLink from 'components/links/Feedback';
import Link from 'components/links/Link';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';


export default function AboutPage() {

  const renderItem = (staff) => {
    const disclaimer = staff.conflict ? <p>{staff.conflict}</p> : null;
    const dlIcon = staff.biosketchUrl && staff.biosketchUrl.endsWith('.pdf') ? <DownloadIcon /> : null;
    const bsLink = staff.biosketchUrl && <span><Link newTab={true} href={staff.biosketchUrl}>{dlIcon}NIH Biosketch</Link></span>;

    return (
      <div key={staff.name} className="staff-item">
        <img className="avatar" src={staff.image} alt={staff.name} />
        <div className="staff-meta">
          <h4 className="staff-name">{staff.name}</h4>
          <span className="staff-title">{staff.title}</span>
          <div className="staff-details">
            <p>{staff.bio}</p>
            {disclaimer}
            {bsLink}
          </div>
        </div>
      </div>
    );
  };

  const staff = [
    {
      name: 'Teri E. Klein, PhD',
      title: 'Principal Investigator',
      image: tkleinImage,
      bio: `Dr. Klein is the Principal Investigator for the PharmGKB and Professor
         of Biomedical Data Science and Medicine. Dr. Klein is also the Co-Principal Investigator
         for CPIC (Clinical Pharmacogenetics Implementation Consortium). She was recruited from the
         University of California, San Francisco faculty and was the Director of the PharmGKB
         since it began in April 2000 until September 2019. Teri received her PhD in medical
         information sciences from the University of California, San Francisco and a BA in
         biology/chemistry from the University of California, Santa Cruz. Prior to becoming the
         director of the PharmGKB in 2000, Dr. Klein was an Associate Adjunct Professor at UCSF.`,
      biosketchUrl: 'https://www.ncbi.nlm.nih.gov/myncbi/teri.klein.1/cv/608454/',
    },
    {
      name: 'Michelle Whirl-Carrillo, PhD',
      title: 'Principal Investigator & Director',
      image: mcarrilloImage,
      bio: `
        Dr. Whirl-Carrillo is the Co-Principal Investigator and Director of the PharmGKB. She has been in the 
        pharmacogenomics (PGx) field for over twenty years and is, or has been, part of a multitude of collaborative 
        projects that leverage structured PGx knowledge for the advancement of personalized medicine. Her research 
        interests include the development of manual and automated curation processes for annotating scientific 
        literature; creation and application of terminologies, definitions and rule-based systems to PGx knowledge to 
        aid computational data analysis and clinical implementation; and automated methods to annotate genotype and 
        sequence data for translation to PGx-based therapeutic recommendations. Michelle received her PhD in Biophysics 
        from Stanford University and her SB in Biology from MIT.`,
      conflict: '',
      biosketchUrl: 'https://www.ncbi.nlm.nih.gov/myncbi/michelle.whirl-carrillo.1/cv/581204/',
    },
    {
      name: 'Li Gong, PhD',
      title: '',
      image: lgongImage,
      bio: `Li is a Senior Scientific Curator in the Department of Biomedical Data Science. She joined
          PharmGKB in 2007. Prior to this, Li worked as a senior scientist at Iconix Biosciences
          Inc. working on microarray data analysis, biomarker discovery and curation for the
          development of DrugMatrixR toxicogenomics database. She holds a PhD in Molecular Biology
          and Microbiology from Tufts University School of Medicine, and a BS in Microbiology from
          Beijing University, P.R. China.`,
      conflict: '',
      biosketchUrl: 'https://www.ncbi.nlm.nih.gov/myncbi/lgong/cv/68491/',
    },
    {
      name: 'Katrin Sangkuhl, PhD',
      title: '',
      image: ksangkuhlImage,
      bio: `Katrin is a Senior Scientific Curator in the Department of Biomedical Data Science and has
          been working for the Pharmacogenomics Knowledgebase (PharmGKB) since 2008. Before joining
          the PharmGKB team, Katrin was a Postdoctoral Fellow at the Department of Reproductive
          Biology & Program for Regenerative Medicine, Stanford University. She received her PhD
          in Biochemistry from the University Leipzig, Germany working in the field of GPCRs and
          signal transduction.`,
      conflict: '',
      biosketchUrl: 'https://www.ncbi.nlm.nih.gov/myncbi/1VyNv9OKhCZ5l/cv/581369/',
    },
    {
      name: 'Caroline F. Thorn, PhD',
      title: '',
      image: cfthornImage,
      bio: `Caroline has been a Scientific Curator since 2003, when she first joined the PharmGKB.
          She took two hiatuses when her children were born and spent a brief time as a Senior
          Program Administrator for the American Association for Cancer Research and returned to the
          PharmGKB in 2015. She has a PhD in Genetics from Trinity College University of Dublin, BSc
          Hons in Biochemistry from University of Bath UK and did postdoctoral training at
          University of Pennsylvania in Pharmacology and pharmacogenomics. She has been a lecturer
          on the University of Pennsylvania Topics in Pharmacogenomics course since 2010 and one of
          her favorite PGx memories is teaching on the Stanford South Africa course.`,
      conflict: '',
      biosketchUrl: 'https://www.ncbi.nlm.nih.gov/myncbi/caroline.thorn.1/cv/581414/',
    },
    {
      name: 'Ingrid Keseler, PhD',
      title: '',
      image: ingridImage,
      bio: `Ingrid joined PharmGKB as a Scientific Curator in 2022.  She previously was a senior biocurator for the 
        EcoCyc model-organism database and the PI of the BsubCyc MOD at SRI International.  Before she discovered 
        biocuration, she was a Senior Scientist at Clontech, where she led the design of probes for human, mouse and 
        rat microarrays.  She holds an MS in Microbiology from the University of Georgia, a PhD in Biochemistry from 
        Stanford University, and did postdoctoral work at the Stanford Human Genome Center.`,
      conflict: '',
    },
    {
      name: 'Mark Woon, BSE',
      title: '',
      image: mwoonImage,
      bio: `Mark is the co-technical lead at PharmGKB and has been with PharmGKB since 2001. He
          received his BSE in computer science from Princeton University. Prior to working on the
          PharmGKB project, Mark worked for Sun Microsystems and Netscape Communications.`,
      conflict: '',
      biosketchUrl: 'https://www.ncbi.nlm.nih.gov/myncbi/markwoon/cv/581201/',
    },
    {
      name: 'Ryan Whaley, BS',
      title: '',
      image: rwhaleyImage,
      bio: `Ryan is a software developer in the Department of Biomedical Data Science and a
          co-technical lead of the PharmGKB. He is a Java developer with a background in database
          administration and project management and has been with the PharmGKB since 2007. Ryan
          received his BS in Computer Science and Mathematics at the University of Iowa and then
          went on to become the database administrator and senior software developer for the
          Department of Internal Medicine at the University of Iowa Hospitals & Clinics.`,
      conflict: '',
      biosketchUrl: 'https://www.ncbi.nlm.nih.gov/myncbi/ryan.whaley.1/cv/581236/',
    },
    {
      name: 'Tiffany Murray, BA',
      title: '',
      image: tmurrayImage,
      bio: `Tiffany coordinates the activities for the PharmGKB co-PIs and is responsible for  communications involving consortia, data licensing, and content
          use. She has a BA from the University of  California at San Diego.`,
      conflict: '',
      biosketchUrl: 'https://www.ncbi.nlm.nih.gov/myncbi/tiffany.murray.1/cv/118013/',
    },
    {
      name: 'Clarissa Klein, BA',
      title: '',
      image: cjkleinImage,
      bio: `Clarissa coordinates the application for FDA Recognition for the PharmGKB’s database
      of clinical annotations, as well as other writing and editing projects. She has worked on and
      off with the PharmGKB since 2015 and received her BA in English from Harvard College. Prior to
      returning to the PharmGKB, Clarissa worked in veterinary medicine and wildlife rehabilitation.`,
      conflict: '',
    },
    {
      name: 'Matt W. Wright, PhD',
      title: '',
      image: matthewImage,
      bio: `Dr. Wright is Director of Stanford ClinGen and is a Senior Research Engineer in the Department of Biomedical 
      Data Science. Matt works on the development of PGx Community Curation interfaces for PharmGKB, among other things. 
      Matt received his PhD in Neuroscience from University College London, and a BSc in Anatomy/Physiology from 
      the University of Bristol. His postdoctoral work has included many biocuration projects, including the HGNC, 
      RefSeq (NCBI, Bethesda, MD), and since 2015 the Stanford ClinGen project. His current research focuses on 
      building the infrastructure to support the efforts of the ClinGen (Clinical Genome Resource) consortium to 
      collect and archive information about clinically relevant genes and genomic variants for use in precision medicine.`,
      conflict: '',
    },
  ];

  const staffList = map(staff, renderItem);

  return (
    <ReadableWidthTemplate title="About Us" className="aboutUs">
      <p>
        The PharmGKB is a pharmacogenomics knowledge resource that encompasses clinical
        information including clinical guidelines and drug labels, potentially clinically
        actionable gene-drug associations and genotype-phenotype relationships. PharmGKB
        collects, curates and disseminates knowledge about the impact of human genetic
        variation on drug responses through the following activities:
      </p>
      <div className="row about-list">
        <div className="col-md-6">
          <ul>
            <li>Annotate genetic variants and gene-drug-disease relationships via literature review</li>
            <li>Summarize important pharmacogenomic genes, associations between genetic variants and drugs, and  drug pathways</li>
            <li>Curate FDA drug labels containing pharmacogenomic information</li>
            <li>Enable consortia examining important questions in pharmacogenomics</li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul>
            <li>Curate and participate in writing pharmacogenomic-based drug dosing guidelines</li>
            <li>Contribute to clinical implementation projects for pharmacogenomics through collaborations</li>
            <li>Publish pharmacogenomic-based drug dosing guidelines, very important pharmacogene summaries and drug-centered pathways</li>
            <li>Display all information on the website and provide comprehensive downloads</li>
          </ul>
        </div>
      </div>

      <div className="aboutUs__contact">
        <div className="container readable-width">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6">
              <h3>Contact Us</h3>
              <div className="row">
                <div className="col-sm-6 col-md-12">
                  <p>
                    <strong>Address:</strong><br />
                    Shriram Center for Bioengineering and<br />
                    Chemical Engineering
                  </p>
                  <p>
                    443 Via Ortega, Room 213<br />
                    BioE Altman Lab MC: 4245<br />
                    Stanford, CA 94305
                  </p>
                </div>
                <div className="col-sm-6 col-md-12">
                  <p><strong>Email:</strong> <FeedbackLink subject="">feedback@pharmgkb.org</FeedbackLink></p>
                  <p><strong>Phone:</strong> (650) 725-0659</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none d-md-block order-2">
              <img src={pgkbMapImage} alt="PharmGKB Map" />
            </div>
          </div>
        </div>
      </div>

      <div className="container aboutUs__team">
        <h2 className="section-heading">The Team</h2>
        <div className="flex-staff">
          {staffList}
          {/* max of 5 staff per row, so only need 4 spacers */}
          <div className="staff-spacer" />
          <div className="staff-spacer" />
          <div className="staff-spacer" />
          <div className="staff-spacer" />
        </div>
      </div>
      <div className="container">
        PharmGKB&apos;s scientific advisory board members are listed on our <a href="/page/sab">SAB page</a>.
      </div>
    </ReadableWidthTemplate>
  );
}
