import clsx from 'clsx';
import {assign, every, map, omitBy, reduce} from 'lodash';
import {FormProvider, useForm} from 'react-hook-form';

import Button from 'components/Button';
import FormCheckboxGroup from 'components/form/CheckboxGroup';
import FormGroup from 'components/form/Group';
import FormSubmit from 'components/form/Submit';
import UndoIcon from 'components/icons/Undo';
import {mapAccId} from 'utils/formUtils';

import './GenePicker.scss';

export default function GenotypeGenePicker({
  genes,
  drugs = [],
  defaultValues: defaultValuesProp,
  defaultDrugs = [],
  onSubmit,
  onUpdate,
}) {
  const defaultValues = assign(
    // start with blank values for all possible genes
    reduce(map(genes, mapGeneToValues), (prev, curr) => ({...prev, ...curr}), {}),
    // assign any genotypes passed in from the query parameters
    defaultValuesProp,
    {drugs});
  const formMethods = useForm({defaultValues});
  const {handleSubmit: rhfHandleSubmit, register, setValue, watch} = formMethods;

  const allFormFields = watch();
  watch(onUpdate);

  const handleSubmit = (data) => {
    onSubmit(mapFromFormValues(data));
  };

  const validate = (allowSingleAllele, genesymbol) => (value, values) => {
    // eslint-disable-next-line no-bitwise
    if (!allowSingleAllele && !value && (!(values[genesymbol][0]) ^ !(values[genesymbol][1]))) {
      return `Must choose 2 valid alleles for ${genesymbol}`;
    }
  };

  const handleReset = (genesymbol) => () => {
    setValue(`${genesymbol}[0]`, '');
    setValue(`${genesymbol}[1]`, '');
    setValue(`${genesymbol}[2]`, '');
  };

  return (
    <div className="gsiGenotypePicker">
      <h3>Pick genotypes to see specific information</h3>
      <FormProvider {...formMethods}>
        <form onSubmit={rhfHandleSubmit(handleSubmit)}>
          <div className="alleleControlGrid">
            <div className="font-weight-bolder font-italic">Gene</div>
            <div className="font-weight-bolder font-italic centered">Genotype</div>
            <div className="font-weight-bolder font-italic centered">or</div>
            <div className="font-weight-bolder font-italic centered">Phenotype</div>
            <div className="font-weight-bolder font-italic centered">Reset</div>
            {map(genes, (gene) => {
              const {genesymbol, allowSingleAllele, displaySingleAllele, alleles, phenotypeOptionMap} = gene;
              const controls = [
                <FormGroup
                  name={`${genesymbol}[0]`}
                  label="Allele 1"
                  className="hidden-label"
                  render={(id, invalid) => (
                    <select id={id} {...register(`${genesymbol}[0]`, {validate: validate(allowSingleAllele, genesymbol), disabled: !!allFormFields[genesymbol][2]})} className={clsx('form-control', {'is-invalid': invalid})}>
                      <option value="" key="noneBlank">--</option>
                      {map(alleles, (a) => <option key={a} value={a}>{a}</option>)}
                    </select>
                  )}
                />,
              ];
              if (!displaySingleAllele) {
                const optionsRight = [];
                if (allowSingleAllele) {
                  optionsRight.push(<option key="noneNA" value="">Not Applicable</option>);
                }
                optionsRight.push(...map(alleles, (a) => <option key={a} value={a}>{a}</option>));
                controls[1] = (
                  <FormGroup
                    name={`${genesymbol}[1]`}
                    label="Allele 2"
                    className="hidden-label"
                    render={(id, invalid) => (
                      <select id={id} {...register(`${genesymbol}[1]`, {validate: validate(allowSingleAllele, genesymbol), disabled: !!allFormFields[genesymbol][2]})} className={clsx('form-control', {'is-invalid': invalid})}>
                        <option value="" key="noneBlank">--</option>
                        {optionsRight}
                      </select>
                    )}
                  />
                );
              } else {
                controls[1] = null;
              }
              if (phenotypeOptionMap) {
                controls[2] = (
                  <FormGroup
                    name={`${genesymbol}[2]`}
                    label="Phenotype"
                    className="hidden-label"
                    render={(id, invalid) => (
                      <select id={id} {...register(`${genesymbol}[2]`, {disabled: !!allFormFields[genesymbol][0] || !!allFormFields[genesymbol][1]})} className={clsx('form-control', {'is-invalid': invalid})}>
                        <option value="" key="noneBlank">--</option>
                        {map(phenotypeOptionMap, (p, k) => <option key={p} value={p}>{k}</option>)}
                      </select>
                    )}
                  />
                );
              }
              return (
                <>
                  <div key={`${genesymbol}-1`} className="gene-item">{genesymbol}</div>
                  <div key={`${genesymbol}-2`} className="container-fluid">
                    <div className="row">
                      <div className="col p-0">{controls[0]}</div>
                      <div className="col p-0">{controls[1]}</div>
                    </div>
                  </div>
                  <div key={`${genesymbol}-3`} className="dip-or-phen">{controls[2] && <span>or</span>}</div>
                  <div key={`${genesymbol}-4`}>{controls[2]}</div>
                  <div key={`${genesymbol}-5`} className="reset-ctl">
                    <Button className="btn-sm btn-outline-secondary" actionHandler={handleReset(genesymbol)}>
                      <UndoIcon />Reset
                    </Button>
                  </div>
                </>
              );
            })}
          </div>
          <h3 className="mt-5 mb-1">Phenoconversion (Optional)</h3>
          <small className="mb-3">Use of the following drugs may affect the resulting phenotype given in this tool.</small>
          <FormCheckboxGroup name="drugs" label="Drugs" options={map(drugs, mapAccId)} values={defaultDrugs} />
          <div className="text-center mt-3">
            <FormSubmit>Make Report</FormSubmit>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

function mapFromFormValues(obj) {
  return omitBy(obj, (arr) => every(arr, (a) => !a));
}

function mapGeneToValues(g) {
  const {genesymbol} = g;
  return {
    [genesymbol]: ['', '', ''],
  };
}
