import PropTypes from 'prop-types';

import VipTierTag from 'components/Tag/VipTier';
import Fact from 'components/shared/fact';

/**
 * A component for showing VIP information in a visually distinct and highlighted section in a gene overview tab
 *
 * For chemicals or variants, use VipRelatedSummary
 *
 * @param {object} props - props container
 * @param {string} props.id - the PA ID of the VIP annotation, used to construct link
 * @param {string} props.tier - the Tier descriptor for this VIP, optional
 * @param {string} props.summary - a short summary, in HTML, optional
 * @param {string} props.geneId - the PA ID of the gene, required
 * @return {React.ReactNode} a block VIP summary node
 */
export default function VipSummary({id, tier, summary, geneId}) {
  const label = <span>Overview {tier && tier !== 'Tier 2' && <VipTierTag className="ml-1" tier={tier} />}</span>;
  return (
    <Fact label={label} html={summary} />
  );
}
VipSummary.propTypes = {
  id: PropTypes.string,
  tier: PropTypes.string,
  summary: PropTypes.string,
  geneId: PropTypes.string,
};
