import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import NotFound from 'components/shared/error/not_found';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import {useGet} from 'helpers/KyHooks';
import VipOverviewTab from 'pages/Vip/OverviewTab';


const propTypes = {
  id: PropTypes.string.isRequired,
};

export default function VipPage({id}) {
  const {response, error} = useGet(`site/vip/${id}`);

  let name = 'Very Important Pharmacogene';
  let body = <Loading />;
  if (error) {
    if (error.response?.status === 404) {
      return <NotFound />;
    }
    body = <KyError kyError={error} />;
  } else if (response) {
    const {approved, published, gene} = response.data;
    name = `Very Important Pharmacogene: ${gene.symbol}`;
    body = <VipOverviewTab id={id} gene={gene} approved={approved} published={published} />;
  }


  return (
    <ReadableWidthTemplate title={name}>
      {body}
    </ReadableWidthTemplate>
  );
}
VipPage.propTypes = propTypes;
