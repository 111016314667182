import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import ApproveButton from 'components/Button/Approve';
import DataAnnotationUpsertButton from 'components/DataAnnotation/UpsertButton';
import HistoryTable from 'components/History/Table';
import LabelOccurrenceSection from 'components/LabelOccurrence';
import MultiColumnList from 'components/List/MultiColumn';
import Loading from 'components/Loading';
import PharmgkbTagButton from 'components/PharmgkbTag/Button';
import EditControls from 'components/edit/EditControls';
import Link from 'components/links/Link';
import ResourceCounts, {convertTypedCounts, resourceCountsProps} from 'components/resource/resourceCounts';
import CuratorOnly from 'components/shared/curator_only';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import useAppContext from 'conf/AppContext';
import {DataSource} from 'conf/enums';
import {historyProps, ontologyTermProps, resourceProps} from 'conf/types';

const propTypes = {
  disease: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    altNames: PropTypes.shape({
      synonym: PropTypes.arrayOf(PropTypes.string),
    }),
    terms: PropTypes.arrayOf(PropTypes.shape({
      term: PropTypes.string,
    })),
    history: PropTypes.arrayOf(historyProps),
  }),
  counts: resourceCountsProps,
  needsApproval: PropTypes.bool,
  description: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
  }),
  labelOccurrences: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    object1: resourceProps,
    object2: resourceProps,
  })),
  descriptionTerm: ontologyTermProps,
};

/**
 * Renders disease overview tab.
 *
 * @param {object} props - props container
 * @param {object} props.disease - disease object
 * @param {object} props.counts - counts object
 * @param {boolean} props.needsApproval - true if object needs to approved
 * @param {object} props.description - description object
 * @param {object[]} props.labelOccurrences - array of link annotations
 * @param {object} props.descriptionTerm - ontology term with description to use
 * @return {JSX.Element}
 */
export default function DiseaseOverviewTab({disease, counts, needsApproval, description,
  labelOccurrences = [], descriptionTerm}) {
  const appContext = useAppContext();

  if (!disease?.id) {
    return <Loading />;
  }
  
  const altNames = disease?.altNames?.synonym ? disease.altNames.synonym : '';
  const resourceCounts = disease?.id ? convertTypedCounts('disease', disease?.id, counts) : [];
  const onApproveSuccess = () => {
    toast.success('Approved.');
    appContext.reloadData();
  };

  return (
    <>
      <EditControls>
        <Button href={`/edit/disease/${disease.id}`}>
          <FontAwesomeIcon icon="edit" /> Edit
        </Button>
        <ApproveButton objCls="disease" objId={disease.id} disabled={!needsApproval} onSuccess={onApproveSuccess} />
        <PharmgkbTagButton objId={disease.id} objCls="disease" />
      </EditControls>

      <FactSection>
        <ResourceCounts counts={resourceCounts} />
      </FactSection>

      <FactSection>
        {descriptionTerm && (
          <Fact label="Definition">
            {descriptionTerm.description}
            <p className="font-italic text-muted text-sm-left">Source: <Link href={descriptionTerm._url} showNewTabIcon={true}>{descriptionTerm.resource}</Link></p>
          </Fact>
        )}
        <Fact
          label="Description"
          html={description?.html}
          hideWhenEmpty={!appContext.isPreview}
          labelBtns={<DataAnnotationUpsertButton targetId={disease.id} type="Description" annotationId={description?.id} />}
        />
      </FactSection>

      {labelOccurrences?.length > 0 && (
        <CuratorOnly>
          <Fact label="Pharmacology">
            <LabelOccurrenceSection occurrences={labelOccurrences} subjectId={disease.id} />
          </Fact>
        </CuratorOnly>
      )}

      <FactSection>
        <Fact label="PharmGKB ID" literal={disease.id} />
        <Fact label="Alternate Names">
          {
            altNames.length === 0 ? <p className="text-muted">None</p>
              : <MultiColumnList data={altNames} />
          }
        </Fact>
        <EditControls>
          <Button href={`/edit/alternateNames/disease/${disease.id}`}>
            <FontAwesomeIcon icon="edit" /> Edit alternate names
          </Button>
        </EditControls>
      </FactSection>

      {disease?.history && (
        <FactSection title="History">
          <HistoryTable history={disease?.history} parentCls="Disease" parentId={disease.id} />
        </FactSection>
      )}
    </>
  );
}
DiseaseOverviewTab.propTypes = propTypes;
