import {format as dateFormat} from 'date-fns/format';
import {forEach, size} from 'lodash';
import {useEffect, useState} from 'react';

import InlinePage from 'components/Page/Inline';
import FullFluidWidthTemplate from 'components/templates/FullFluidWidth';
import useAppContext from 'conf/AppContext';
import {analyticsEvent} from 'helpers/analytics';
import DrugResultGrid from 'pages/genotypeResults/DrugResultGrid';


/**
 * The GSI page for showing recommendations
 *
 * @param {object} props - props
 * @param {object} props.genoQueryParam - genotypes that are coming from a query parameter
 * @param {boolean} props.hideNoRec - hide annotations with no recommendations
 */
export default function GenotypePage({genoQueryParam, hideNoRec}) {
  const appContext = useAppContext();
  const [drugs, setDrugs] = useState({});
  const [phenotypes, setPhenotypes] = useState({});
  const [phenotypesOriginal, setPhenotypesOriginal] = useState({});
  const [alleles, setAlleles] = useState({});
  const [geneAlleleMap, setGeneAlleleMap] = useState({});
  const [errors, setErrors] = useState({}); // errors per gene symbol
  const [phenoconversionDrugs, setPhenoconversionDrugs] = useState([]);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      try {
        const rez = await appContext.api.post(
          'site/gsi',
          {parseJson: true, json: genoQueryParam, searchParams: {view: 'most'}},
        );
        if (mounted) {
          setErrors(rez?.data?.errors);
          setPhenotypes(rez?.data?.phenotypes);
          setPhenotypesOriginal(rez?.data?.phenotypesOriginal);
          setAlleles(rez?.data?.alleles);
          setGeneAlleleMap(rez?.data?.geneAlleleMap);
          setPhenoconversionDrugs(rez?.data?.phenoconversionDrugs);
          const newDrugs = {...rez?.data?.drugs};
          forEach(Object.values(newDrugs), (drug) => {
            const allAnnotations = [
              ...drug.cpic.annotations,
              ...drug.dpwg.annotations,
              ...drug.fda.annotations,
              ...drug.fdaPgx.annotations];
            for (let k = 0; k < allAnnotations.length; k += 1) {
              const anno = allAnnotations[k];
              for (let j = 0; j < size(anno.recommendationAnnotations); j += 1) {
                const rec = anno.recommendationAnnotations[j];
                rec.open = false;
              }
            }
          });
          setDrugs(newDrugs);
        }
      } catch (error) {
        setErrors(error);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadData();
    return () => {
      mounted = false;
    };
  }, [genoQueryParam]);

  /**
   * Handle the request to open or close a recommendation from view in the page
   *
   * @param {string} id - the ID for the RecommendationAnnotation to change, if falsey it will apply to ALL RecommendationAnnotations
   * @param {boolean} open - true to open the annotation(s), false to close the annotation(s)
   */
  const handleOpenChange = (id, open) => {
    analyticsEvent('gsi-openRecommendation', {
      switchTo: open ? 'close' : 'open',
      rec: id || 'all',
    });

    setDrugs((p) => {
      const newDrugs = {...p};
      forEach(Object.values(newDrugs), (drug) => {
        const allAnnotations = [
          ...drug.cpic.annotations,
          ...drug.dpwg.annotations,
          ...drug.fda.annotations,
          ...drug.fdaPgx.annotations];
        for (let i = 0; i < allAnnotations.length; i += 1) {
          const anno = allAnnotations[i];
          for (let j = 0; j < size(anno.recommendationAnnotations); j += 1) {
            const rec = anno.recommendationAnnotations[j];
            if (!id || rec.id === id) {
              rec.open = open;
            }
          }
        }
      });
      return newDrugs;
    });
  };

  return (
    <FullFluidWidthTemplate title="GSI: Results">
      <div className="container mb-3">
        <p>
          <span className="font-italic">Generated:</span> {dateFormat(Date.now(), 'M/dd/yyyy h:mm aaa')}
        </p>
        <InlinePage id="gsiHeader" />
        <InlinePage id="gsiReportHeader" className="mb-3" />
      </div>
      <DrugResultGrid
        drugs={drugs}
        geneAlleleMap={geneAlleleMap}
        alleles={alleles}
        phenotypes={phenotypes}
        phenotypesOriginal={phenotypesOriginal}
        onOpenChange={handleOpenChange}
        errors={errors}
        hideNoRec={hideNoRec}
        phenoconversionDrugs={phenoconversionDrugs}
      />
    </FullFluidWidthTemplate>
  );
}
