import {useEffect} from 'react';

import Loading from 'components/Loading';
import useSearchBar from 'components/Search/Bar/Provider';
import SideNav from 'components/SideNav';
import {getMenuItems, getTabComponent} from 'components/SideNav/utils';
import KyError from 'components/errors/KyError';
import ReadableWidthResourceTemplate from 'components/templates/ReadableWidthResource';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import {useGet} from 'helpers/KyHooks';
import VariationHaplotypeOverviewTab from 'pages/VariationHaplotype/OverviewTab';

export default function VariationHaplotypePage({id, loadId = 0, section}) {
  const appContext = useAppContext();
  const searchBar = useSearchBar();
  const showOverview = !section || section === 'overview';

  const {response, error} = useGet(`site/variationHaplotype/${id}`, {
    cacheResponse: !appContext.isPreview, searchParams: {view: 'most'},
  }, {
    loadId,
  });
  useEffect(() => {
    if (response) {
      const {variationHaplotype} = response.data;
      searchBar.initializeSearchBar([variationHaplotype]);
    }
  }, [response]);


  let body = <Loading />;
  let title = 'Haplotype';
  let menuItems = [];
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    const {variationHaplotype, counts, previousHaplotype} = response.data;
    title = variationHaplotype.symbol;
    menuItems = getMenuItems('variationHaplotype', Types.variationHaplotype.url(id), counts);

    if (showOverview) {
      body = <VariationHaplotypeOverviewTab {...variationHaplotype} previousHaplotype={previousHaplotype} />;
    } else {
      const Comp = getTabComponent(section);
      body = <Comp {...variationHaplotype} />;
    }
  }
  
  return (
    <ReadableWidthResourceTemplate type="variationHaplotype" id={id} name={title}>
      <SideNav items={menuItems}>
        {body}
      </SideNav>
    </ReadableWidthResourceTemplate>
  );
}
