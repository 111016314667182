import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {filter, isArray, map, omit, size, some} from 'lodash';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import ApproveButton from 'components/Button/Approve';
import EditButton from 'components/Button/Edit';
import Loading from 'components/Loading';
import PharmgkbTagButton from 'components/PharmgkbTag/Button';
import SimpleTable from 'components/Table/Simple';
import {flexColumnStyle} from 'components/Table/columnHelpers';
import Tag from 'components/Tag';
import AmpTierTag from 'components/Tag/AmpTier';
import EditControls from 'components/edit/EditControls';
import DownloadLink from 'components/links/Download';
import InfoLink from 'components/links/Info';
import Link from 'components/links/Link';
import ResourceLink from 'components/links/Resource';
import ResourceCounts, {convertTypedCounts, resourceCountsProps} from 'components/resource/resourceCounts';
import CuratorOnly from 'components/shared/curator_only';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import VipRelatedSummary from 'components/vip_gene/VipRelatedSummary';
import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';
import Types, {haplotypeProps} from 'conf/types';
import {HaplotypeFrequencySection, sortAndLabelFrequencies} from 'pages/Haplotype/FrequencySection';


const linkedHgvsCellRenderer = ({row, value}) => {
  let text;
  if (isArray(value)) {
    text = [];
    for (let x = 0; x < value.length; x += 1) {
      if (x !== 0) {
        text.push(<span key={x}>, </span>);
      }
      text.push(value[x]);
    }
  } else if (value) {
    text = value;
  } else {
    return null;
  }
  return <Link href={Types.variant.url(row.original.variant.id)}>{text}</Link>;
};

const hgvsCellRenderer = ({value}) => {
  if (isArray(value)) {
    const text = [];
    for (let x = 0; x < value.length; x += 1) {
      if (x !== 0) {
        text.push(<span key={x}>, </span>);
      }
      text.push(value[x]);
    }
    return text;
  } else if (value) {
    return value;
  } else {
    return null;
  }
};

const findHgvsIndex = (chrChanges, allele) => {
  if (allele.length === 1) {
    const target = '>' + allele;
    for (let x = 0; x < chrChanges.length; x += 1) {
      if (chrChanges[x].endsWith(target)) {
        return x;
      }
    }
  }
  return -1;
};

const hgvsRegex = /^(.+:)(.+\d+)([A-Za-z]+.*)$/;

function addWbr(hgvs) {
  const match = hgvs.match(hgvsRegex);
  if (match) {
    return <span key={hgvs}>{match[1]}<wbr />{match[2]}<wbr />{match[3]}</span>;
  }
  return hgvs;
}

const hgvsAccessor = (originalRow, isReference, type) => {
  const seqLoc = originalRow?.variant?.locations?.[0];
  if (!seqLoc) {
    return '';
  }
  const chrChanges = seqLoc.altHgvs?.[type];
  if (!chrChanges) {
    return chrChanges;
  }
  if (isReference) {
    if (originalRow.allele === 'del') {
      // what should we do here?  see TPMT*1
    } else {
      // first check to see if allele is in variantAlleles (happens with repeats)
      const idx = seqLoc.variantAlleles.indexOf(originalRow.allele);
      if (idx !== -1) {
        const hgvs = chrChanges[idx];
        if (hgvs) {
          const match = hgvs.match(hgvsRegex);
          if (match) {
            if (hgvs.includes('[')) {
              return <>{match[1]}<wbr />{match[2]}<wbr />{match[3]}</>;
            } else {
              return <>{match[1]}<wbr />{match[2]}<wbr />=</>;
            }
          }
          return hgvs;
        }
      }
      const chrChange = chrChanges[0];
      if (!chrChange.includes('[')) {
        const match = chrChange.match(hgvsRegex);
        if (match) {
          return <>{match[1]}<wbr />{match[2]}<wbr />=</>;
        }
      }
    }
  } else {
    // first check to see if allele is in variantAlleles (happens with repeats)
    let idx = seqLoc.variantAlleles.indexOf(originalRow.allele);
    if (idx !== -1) {
      const hgvs = chrChanges[idx];
      if (hgvs) {
        return addWbr(hgvs);
      }
    }
    if (chrChanges.length > 1) {
      idx = -1;
      if (type === 'c') {
        idx = findHgvsIndex(chrChanges, originalRow.allele);
      } else {
        const cChrChanges = originalRow?.variant?.locations?.[0]?.altHgvs?.c;
        if (cChrChanges.length === chrChanges.length) {
          idx = findHgvsIndex(cChrChanges, originalRow.allele);
        } else {
          logger.warn(`Got ${cChrChanges.length} chromosome changes but ${chrChanges.length} ${type} changes`);
        }
      }
      if (idx !== -1) {
        return addWbr(chrChanges[idx]);
      }
    }
  }
  const hgvs = [];
  for (let x = 0; x < chrChanges.length; x += 1) {
    hgvs.push(addWbr(chrChanges[x]));
  }
  return hgvs;
};

const getHgvsColumns =  (isReference) => {
  const cols = [
    {
      id: 'c',
      Header: 'Chromosome change',
      accessor: (originalRow) => hgvsAccessor(originalRow, isReference, 'c'),
      Cell: linkedHgvsCellRenderer,
      ...flexColumnStyle({width: 150, grow: 4}),
    },
    {
      id: 'g',
      Header: 'Gene change',
      accessor: (originalRow) => hgvsAccessor(originalRow, isReference, 'g'),
      Cell: hgvsCellRenderer,
      ...flexColumnStyle({width: 150, grow: 4}),
    },
  ];
  if (!isReference) {
    cols.push({
      id: 'p',
      Header: 'Protein change',
      accessor: (originalRow) => hgvsAccessor(originalRow, isReference, 'p'),
      Cell: hgvsCellRenderer,
      ...flexColumnStyle({width: 120, grow: 3}),
    });
  }
  return cols;
};

const getColumns = (hasHgvs, isReference) => [
  ...(hasHgvs ? getHgvsColumns(isReference) : []),
  ...(!isReference ? [{
    id: 'variant',
    Header: 'Variant',
    accessor: 'allele',
    ...flexColumnStyle({width: 80}),
  }] : []),
  {
    id: 'reference',
    Header: 'Reference',
    accessor: (originalRow) => {
      if (isReference) {
        return originalRow?.allele;
      }
      return originalRow?.variant?.locations?.[0]?.referenceAllele ?? '';
    },
    ...flexColumnStyle({width: 90}),
  },
  {
    id: 'dbSnp',
    Header: 'dbSNP',
    accessor: 'variant.crossReferences',
    Cell: ({value}) => {
      if (value && value.length > 0) {
        return <Link href={value[0]._url} showNewTabIcon={true}>{value[0].resourceId}</Link>;
      }
      return null;
    },
    ...flexColumnStyle({width: 130, grow: 2}),
  },
];

const iupacExtendedCodes = /[RYMKSWHBVDN]/gm;


const propTypes = {
  haplotype: haplotypeProps,
  isCpic: PropTypes.bool,
  isPharmVarAllele: PropTypes.bool,
  description: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
  }),
  counts: resourceCountsProps,
  frequency: PropTypes.object,
  vips: PropTypes.arrayOf(PropTypes.object),
  alleleFile: PropTypes.string,
  sourceId: PropTypes.number,
  needsApproval: PropTypes.bool,
  subId: PropTypes.string,
  subStatus: PropTypes.string,
};
/**
 * Renders the overview tab for the haplotype page.
 */
export default function HaplotypeOverviewTab({haplotype, isCpic = false, isPharmVarAllele = false,
  description, counts, frequency, vips = [], alleleFile, sourceId, needsApproval, relatedAlleles = [],
  subId, subStatus, alleleType, migratedHaplotype}) {
  const appContext = useAppContext();

  const {copyNumber, original, reference} = haplotype;
  const showFrequencies = !reference && haplotype.symbol !== 'CYP2C19*1' && frequency;

  if (!haplotype?.id) {
    return <Loading />;
  }

  const resourceCounts = haplotype?.id ? convertTypedCounts('haplotype', haplotype?.id, counts) : [];
  let body;
  if (copyNumber === '0') {
    body = <p>Whole gene deletion.</p>;
  } else if (copyNumber !== '1') {
    if (!original) {
      body = <p>Gene copy number: {copyNumber}</p>;
    } else {
      body = (
        <p>
          Copy number: {copyNumber} of allele <ResourceLink resource={original} />
        </p>
      );
    }
  } else if (haplotype.structuralVariation) {
    body = <p>Structural variation: {haplotype.structuralVariation}</p>;

  } else {
    const data = filter(haplotype.alleles, (l) => (l.allele));
    // TODO(markwoon): this check should be unnecessary once we've updated all the data
    let hasHgvs = false;
    for (let x = 0; x < data.length; x += 1) {
      if (data[x].variant?.locations?.[0]?.altHgvs?.c) {
        hasHgvs = true;
        break;
      }
    }
    let dlHref;
    if (alleleFile) {
      dlHref = appContext.downloadAttachmentUrl(alleleFile);
    } else if (sourceId) {
      dlHref = appContext.apiUrl(`/download/submission/${sourceId}`);
    }
    const hasExtendedIupacSymbol = some(data, (o) => iupacExtendedCodes.test(o?.allele));
    body = (
      <>
        <p>
          Any chromosomal positions listed below are assumed to be on the GRCh38 assembly. Be aware, the assembly
          may differ for variants elsewhere on the PharmGKB site.
        </p>
        <div>
          {dlHref && <p><DownloadLink href={dlHref}>Download Allele Definition Table</DownloadLink></p>}
        </div>

        {hasExtendedIupacSymbol && (
          <div>
            <FontAwesomeIcon icon="info-circle" className="text-info" /> This table uses extended IUPAC codes to define alleles. <Link href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2865858/" newTab={true}>See PMC2865858 for definitions</Link>.
          </div>
        )}

        <SimpleTable
          className="mt-5 table-sm"
          data={data}
          columns={getColumns(hasHgvs, reference)}
        />
      </>
    );
  }

  const sortedFrequency = sortAndLabelFrequencies(frequency);
  const notApproved = subStatus !== 'Approved';

  let carSection;
  if (alleleType === 'namedVariants') {
    if (size(relatedAlleles) > 0) {
      carSection = (
        <ul>
          {map(relatedAlleles, (a) => <li key={a.id}><ResourceLink resource={omit(a, 'symbol')} /></li>)}
        </ul>
      );
    } else if (haplotype.hgvs) {
      carSection = <p>Not associated: <Link href={`https://reg.clinicalgenome.org/redmine/projects/registry/genboree_registry/allele?hgvsOrDescriptor=${encodeURIComponent(haplotype.hgvs)}`}>look up on ClinVar</Link></p>;
    } else {
      carSection = <p>Link unavailable, no HGVS string for this named variant</p>;
    }
  } else {
    if (migratedHaplotype) {
      carSection = <p>Migrated to <ResourceLink resource={migratedHaplotype} /></p>;
    }
    else {
      carSection = <p>Not yet migrated</p>;
    }
  }

  return (
    <>
      <EditControls>
        <EditButton href={`/edit/haplotypes/${haplotype.gene.id}`} />
        <PharmgkbTagButton objId={haplotype.id} objCls="haplotype" />
        <ApproveButton objCls="Haplotype" objId={haplotype.id} label="Approve Haplotype" disabled={!needsApproval} />
        <Button key="submission" href={`/submission/${subId}`} className="btn-secondary">
          <FontAwesomeIcon icon={['far', 'file']} /> Submission <span className={clsx({'text-danger': notApproved})}>({subStatus})</span>
        </Button>
      </EditControls>

      <FactSection>
        <ResourceCounts counts={resourceCounts} />
        {(isCpic || isPharmVarAllele || haplotype.ampTier) && (
          <div className="mt-3">
            {isCpic && <Tag className="tag--sm">CPIC Gene</Tag>}
            {isPharmVarAllele && <Tag className="tag--sm">PharmVar Allele</Tag>}
            {haplotype.ampTier && <AmpTierTag className="tag--sm" tier={haplotype.ampTier} />}
          </div>
        )}
      </FactSection>
      {renderEditControls(haplotype.id, description)}

      {description?.html && (
        <FactSection>
          <Fact label="Description" html={description.html} />
        </FactSection>
      )}

      <FactSection compact={true}>
        <Fact label="PharmGKB ID" literal={haplotype.id} />
        <Fact label="Gene"><ResourceLink resource={haplotype.gene} /></Fact>
        <Fact label="HGVS Representation" literal={haplotype.hgvs} />
        <Fact
          label="CPIC Function Assignment"
          literal={haplotype.functionTerm?.term}
          labelBtns={<InfoLink href="/page/cpicFuncPhen" tooltip="More info on allele function" />}
        />
        {haplotype?.activityValue && (
          <Fact
            label="CPIC Activity Value"
            literal={haplotype.activityValue}
            labelBtns={<InfoLink href="/page/cpicFuncPhen" tooltip="More info on allele activity values" />}
          />
        )}
        <Fact
          label="DPWG Function Assignment"
          literal={haplotype.dpwgFunctionTerm?.term}
          labelBtns={<InfoLink href="/page/dpwgMapping" tooltip="More info on DPWG mapping" />}
        />
      </FactSection>

      <FactSection title="Definition">
        {body}
      </FactSection>

      {showFrequencies && <HaplotypeFrequencySection frequency={sortedFrequency} alleleName={haplotype.symbol} />}

      <CuratorOnly>
        <h3>ClinGen Alleles</h3>
        {carSection}
        {vips?.length > 0 && (
          map(vips, (vip) => <VipRelatedSummary {...vip} key={vip.url} />)
        )}
      </CuratorOnly>
    </>
  );
}
HaplotypeOverviewTab.propTypes = propTypes;


function renderEditControls(haplotypeId, description) {
  return (
    <EditControls className="editControls--sm">
      {(description && description.id)
        ? (
          <EditButton href={`/edit/dataAnnotation/${description.id}`} label="Edit Description" />
        ) : (
          <Button key="overview" href={`/edit/dataAnnotation?type=Description&target=${haplotypeId}`}>
            <FontAwesomeIcon icon="plus" /> Add Overview
          </Button>
        )}
    </EditControls>
  );
}
